// packages
import React, { useState, useRef, useCallback, useEffect, forwardRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames/bind';
import dayjs from 'dayjs';
import validator from 'validator';
// custom
import amplitude from 'service/amplitude';
import styles from './index.module.sass';
import './transition.css';
import Button from 'components/button';
import Wrapper from 'components/wrapper';
import Loading from 'components/loading';
import { chargingPowerTwoDecimal } from 'utils/helper';
import { ChargingRecordsProvider, useChargingRecordsProvider } from './use-charging-records';
import {
  INIT,
  INIT_AUTH,
  START_SESSION,
  WAITING_CHARGING,
  IN_CHARGING,
  COMPLETE_CHARGING,
  COMPLETE_SESSION,
  FINISH_CHARGING,
  FORCE_STOP_CHARGING,
} from 'config';
// image
import iconUnplug from 'images/icon-unplug.svg';
import iconMentosCheck from 'images/icon_circlecheck.png';
import iconHint from 'images/icon-hint.png';
import imgConnect from 'images/icon-connect.png';
import iconRefresh from 'images/icon-refresh.svg';
import mentosVideo from 'images/mentos_video.mp4';
import mentosPoster from 'images/mentos_poster.png';
import { ReactComponent as IconPnc } from 'images/icon-pnc.svg';
import { useInterval } from 'utils/use-interval';
import { MENTOS_LOADED } from 'config';
import { MENTOS_READY } from 'config';
import { MENTOS_ANIMATE } from 'config';
import { MENTOS_INIT } from 'config';
import { useParams } from 'react-router';

// dayjs extends
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const cx = classnames.bind(styles);

const resultByChargingState = (state, events) => {
  switch (state) {
    case IN_CHARGING:
      return events.charging();
    case COMPLETE_CHARGING:
      return events.completeCharging();
    case FINISH_CHARGING:
      return events.finishCharging();
    case COMPLETE_SESSION:
    case FORCE_STOP_CHARGING:
      return events.forceStopCharging();
    default:
      return;
  }
};

export const replaceWithBr = text => {
  const textGroup = text.split('\n');
  return textGroup.map((x, key) => {
    if (key === textGroup.length - 1) {
      return <React.Fragment key={key}>{x}</React.Fragment>;
    }
    return (
      <React.Fragment key={key}>
        {x}
        <br />
      </React.Fragment>
    );
  });
};

const ModifyEmailDialog = forwardRef(({ serialNumber, ...chargingRecordState }, ref) => {
  const {
    chargingData: data,
    email,
    setEmail,
    modifyEmailDialogShow,
    errorMessage,
    setErrorMessage,
    setModifyEmailDialogShow,
    modifyEmail,
    mailModifying,
  } = chargingRecordState;

  const inputRef = useCallback(
    node => {
      if (modifyEmailDialogShow && node !== null) node.focus();
    },
    [modifyEmailDialogShow]
  );

  const maskClass = cx({
    mask: true,
    show: modifyEmailDialogShow,
  });

  const inputClass = cx({ error: errorMessage });

  const dialogButtonClass = cx({
    'dialog-button': true,
    disabled: email.length === 0,
  });

  const LoadingIcon = () => {
    return (
      <div className={styles.loading}>
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  };

  const onMaskClick = e => {
    if (e.target.id === 'mask') {
      setErrorMessage('');
      setModifyEmailDialogShow();
    }
  };

  const onButtonClick = () => {
    const amplitudeParams = {
      serialNumber,
      evseId: data.evseId,
      evseName: data.evse.uid,
      email,
    };

    resultByChargingState(data.state, {
      charging: () => amplitude.chargingConfirmEmailOnClick(amplitudeParams),
      completeCharging: () => amplitude.chargingCompleteConfirmEmailOnClick(amplitudeParams),
    });
    modifyEmail();
  };

  return (
    <div ref={ref} className={maskClass} id="mask" onClick={onMaskClick}>
      <div className={styles.dialog}>
        <div className={styles.title}>修改信箱</div>
        <input
          className={inputClass}
          ref={inputRef}
          type="email"
          placeholder="請輸入信箱"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <div className={styles['error-message']}>{errorMessage}</div>
        <button disabled={email.length === 0 || mailModifying} className={dialogButtonClass} onClick={onButtonClick}>
          {mailModifying ? <LoadingIcon /> : '確認'}
        </button>
      </div>
    </div>
  );
});

const Mentos = ({ state }) => {
  const [mentosState, setMentosState] = useState(MENTOS_INIT);
  const videoRef = useRef('');

  // mentos 影片載入 or 強制中斷時再開始播放
  useEffect(() => {
    const videoSelf = videoRef.current;
    const mentosLoad = () => setMentosState(MENTOS_LOADED);

    if (videoSelf) {
      // 有影片時切換到 loaded
      videoSelf.addEventListener('loadedmetadata', mentosLoad);
    } else {
      // 沒影片時直接切換到 ready
      setMentosState(MENTOS_READY);
    }

    return () => {
      videoSelf.removeEventListener('loadedmetadata', mentosLoad);
    };
  }, []);

  useEffect(() => {
    const videoSelf = videoRef.current;
    let playPromise;
    let mentosReady;
    let mentosAnimate;

    // 影片切換到 loaded 時執行
    if (mentosState === MENTOS_LOADED) {
      mentosReady = setTimeout(() => {
        playPromise = videoSelf.play();
        playPromise.then(() => console.log('run video')).catch(err => console.log(err));
        setMentosState(MENTOS_READY);
      }, 300);
    }

    // 充電中切換到 ready 時執行
    if (mentosState === MENTOS_READY && state === IN_CHARGING) {
      mentosAnimate = setTimeout(() => {
        setMentosState(MENTOS_ANIMATE);
      }, [2000]); // 看能否抓影片的秒數
    }

    return () => {
      clearTimeout(mentosReady);
      clearTimeout(mentosAnimate);
    };
  }, [mentosState, state]);

  const mentosClass = cx({
    mentos: true,
    ready: mentosState === MENTOS_READY,
    animate: mentosState === MENTOS_ANIMATE,
  });

  if (state === COMPLETE_SESSION || state === FORCE_STOP_CHARGING) {
    return (
      <div className={mentosClass}>
        <img src={iconHint} alt="hint" className={styles['mentos-error']} />
      </div>
    );
  }

  return (
    <div className={mentosClass}>
      <div className={styles['mentos-video']}>
        <video preload="auto" poster={mentosPoster} ref={videoRef}>
          <source src={mentosVideo} type="video/mp4" />
        </video>
      </div>
      {(state === FINISH_CHARGING || state === COMPLETE_SESSION || state === COMPLETE_CHARGING) && (
        <img src={iconMentosCheck} alt="check" className={styles.check} />
      )}
    </div>
  );
};

const ModifyEmail = ({ data, setDialogShow, serialNumber }) => {
  if (data.state === FINISH_CHARGING || data.state === COMPLETE_SESSION || data.state === FORCE_STOP_CHARGING) {
    return <></>;
  }

  const emailIsValid = validator.isEmail(data.email);

  const Content = () => {
    if (emailIsValid) {
      return (
        <>
          將會 Email 至此信箱通知您最新充電狀態。
          <br />
          {data.email}{' '}
        </>
      );
    } else {
      return <>新增 Email 來取得您的充電狀態 </>;
    }
  };

  return (
    <div className={styles['modify-email']}>
      <Content />
      <span
        onClick={() => {
          const amplitudeParams = {
            serialNumber,
            evseId: data.evseId,
            evseName: data.evse.uid,
            email: data.email,
          };

          resultByChargingState(data.state, {
            charging: () => amplitude.chargingModifyEmailOnClick(amplitudeParams),
            completeCharging: () => amplitude.chargingCompleteModifyEmailOnClick(amplitudeParams),
          });
          setDialogShow(true);
        }}
      >
        {emailIsValid ? '修改' : '新增'}
      </span>
    </div>
  );
};

const InCharging = ({ serialNumber, ...chargingRecordState }) => {
  const [autoRefreshDelay, setAutoRefreshDelay] = useState(5000);

  const {
    chargingData: data,
    setModifyEmailDialogShow,
    modifyEmailDialogShow,
    geolocationFetchFail,
    NavigationMapDialog,
    fetchGeoLocation,
    fetchChargingRecord,
  } = chargingRecordState;
  const mailDialogRef = useRef();

  useEffect(() => {
    if (modifyEmailDialogShow) {
      mailDialogRef.current.childNodes[0].scrollIntoView({ behavior: 'smooth' });
    }
  }, [modifyEmailDialogShow]);

  useInterval(() => {
    fetchChargingRecord();
  }, autoRefreshDelay);

  // 累積充電時間
  const accTime = () => {
    let endingStateTime = () => {
      switch (data.state) {
        case FINISH_CHARGING:
          return data.disabledChargingAt;
        case COMPLETE_SESSION:
        case FORCE_STOP_CHARGING:
          return data.stopAt;
        default:
          return data.updatedAt;
      }
    };

    let startTime = dayjs(data.enabledChargingAt).startOf('second');
    let endTime = dayjs(endingStateTime()).startOf('second');

    return Math.ceil(dayjs(endTime).diff(startTime, 'second') / 60);
  };

  // 充電費用
  const chargingAmount = () => {
    if (data.state === FINISH_CHARGING || data.state === FORCE_STOP_CHARGING) {
      return data.amount;
    }
    if(parseFloat(data.evse.chargingPeriod.pricePerMinute) !== 0){
      return Math.ceil(parseFloat(data.evse.chargingPeriod.pricePerMinute) * accTime());
    }else if (parseFloat(data.evse.chargingPeriod.pricePerKWh) !== 0){
      return Math.ceil( parseFloat(data.evse.chargingPeriod.pricePerKWh) * parseFloat(data.energy));
    }else{
      return 0;
    }
  };

  useEffect(() => {
    const amplitudeParams = {
      serialNumber,
      evseId: data.evseId,
      evseName: data.evse.uid,
      accTime: accTime(),
      startTime: dayjs(data.enabledChargingAt).format('YYYY-MM-DD HH:mm'),
      currentTime: dayjs().format('YYYY-MM-DD HH:mm'),
      watt: chargingPowerTwoDecimal(data.energy),
      amount: chargingAmount(),
    };

    resultByChargingState(data.state, {
      charging: () => amplitude.chargingPageView(amplitudeParams),
      completeCharging: () => amplitude.chargingCompletePageView(amplitudeParams),
      finishCharging: () => amplitude.chargingFinishPageView(amplitudeParams),
      forceStopCharging: () => amplitude.chargingForceStopPageView(amplitudeParams),
    });
  }, []); // eslint-disable-line

  const Heading = useCallback(() => {
    const getText = resultByChargingState(data.state, {
      charging: () => ({ title: `您的車輛\n正在充電中` }),
      completeCharging: () => ({ title: `預估已完成充電\n請前往拔槍取車並扣款`, subTitle: '未拔槍將持續收取充電費用' }),
      finishCharging: () => ({ title: `已完成充電付款\n謝謝您使用皮卡充` }),
      forceStopCharging: () => ({
        title: `計費已達預授權金額\n已強制結束充電\n請儘速前往取車`,
        subTitle: '超時將影響下次充電權益',
      }),
    });

    const { title, subTitle } = getText;

    return (
      <div className={styles.heading}>
        <div className={styles.title} data-testid="title">
          {replaceWithBr(title)}
        </div>
        {subTitle && (
          <div className={styles['sub-title']} data-testid="subtitle">
            {subTitle}
          </div>
        )}
      </div>
    );
  }, [data.state]);

  const UnplugHint = useCallback(() => {
    if (data.state === FINISH_CHARGING) return <></>;

    return (
      <div className={styles.hint}>
        <img src={iconUnplug} alt="unplug-hint" />
        <span>欲結束充電請拔除充電槍並掛回皮卡充將自動完成扣款。</span>
      </div>
    );
  }, [data.state]);

  const NavigateButton = () => {
    if (data.state === FINISH_CHARGING) return <></>;

    return (
      <div className={styles.button}>
        <Button
          onClick={() => {
            const amplitudeParams = { serialNumber, evseId: data.evseId, evseName: data.evse.uid, email: data.email };

            resultByChargingState(data.state, {
              charging: () => amplitude.chargingNavigationOnClick(amplitudeParams),
              completeCharging: () => amplitude.chargingCompleteNavigationOnClick(amplitudeParams),
              forceStopCharging: () => amplitude.chargingForceStopNavigationOnClick(amplitudeParams),
            });

            fetchGeoLocation();
          }}
        >
          導航前往取車
        </Button>
        {geolocationFetchFail && <div className={styles.error}>讀取位置失敗，請再次嘗試。</div>}
      </div>
    );
  };

  const AmountLabel = () => {
    if (data.state === FINISH_CHARGING || data.state === COMPLETE_SESSION || data.state === FORCE_STOP_CHARGING) {
      return '付款金額';
    }
    return '預計費用';
  };

  const UpdatedAtLabel = () => {
    switch (data.state) {
      case FINISH_CHARGING:
        return '完成時間';
      case COMPLETE_SESSION:
      case FORCE_STOP_CHARGING:
        return '停止時間';
      default:
        return '更新時間';
    }
  };

  const UpdateAt = () => {
    const time = () => {
      switch (data.state) {
        case FINISH_CHARGING:
          return data.disabledChargingAt;
        case COMPLETE_SESSION:
        case FORCE_STOP_CHARGING:
          return data.stopAt;
        default:
          return data.updatedAt;
      }
    };

    return dayjs(time()).format('YYYY/M/D HH:mm:ss');
  };
  const rate = () => {
    if(parseFloat(data.evse.chargingPeriod.pricePerMinute) !== 0){
      return `$${data.evse.chargingPeriod.pricePerMinute}/分鐘`;
    }else if (parseFloat(data.evse.chargingPeriod.pricePerKWh) !== 0){
      return `$${data.evse.chargingPeriod.pricePerKWh}/度`;
    }else{
      return '';
    }
  }
  return (
    <div className={styles['in-charging']}>
      {NavigationMapDialog()}
      <ModifyEmailDialog ref={mailDialogRef} {...chargingRecordState} serialNumbe={serialNumber} />
      <Heading />
      <div className={styles.detail}>
        <Mentos state={data.state} />
        <div className={styles.basic}>
          <div className={styles.code}>{data.evse.uid}</div>
          <div className={styles.info}>
            <div>
              <div className={styles.name}>一般費率</div>
              <div className={styles.value}>{rate()}</div>
            </div>
            <div>
              <div className={styles.name}>充電功率</div>
              <div className={styles.value}>{chargingPowerTwoDecimal(data.evse.watt)} kw</div>
            </div>
          </div>
        </div>
        <ul className={styles.list}>
          <li className={styles.item}>
            <div className={styles.name}>累計充電時間</div>
            <div className={styles.value}>{accTime()} 分</div>
          </li>
          <ul className={styles.more}>
            <li className={styles.item}>
              <div className={styles.name}>開始時間</div>
              <div className={styles.value}>{dayjs(data.enabledChargingAt).format('YYYY/M/D HH:mm:ss')}</div>
            </li>
            <li className={styles.item}>
              <div className={styles.name}>
                <UpdatedAtLabel />
              </div>
              <div className={styles.value}>
                <UpdateAt />
              </div>
            </li>
          </ul>
          <li className={styles.item}>
            <div className={styles.name}>充電瓦數</div>
            <div className={styles.value}>{chargingPowerTwoDecimal(data.energy)} kWh</div>
          </li>
          <li className={`${styles.item} ${styles.amount}`}>
            <div className={styles.name}>
              <AmountLabel />
            </div>
            <div className={styles.value}>${chargingAmount()}</div>
          </li>
        </ul>
        <UnplugHint />
      </div>
      <ModifyEmail data={data} setDialogShow={setModifyEmailDialogShow} serialNumber={serialNumber} />
      <NavigateButton />
    </div>
  );
};

const InWaiting = ({ serialNumber, ...chargingRecordState }) => {
  const { chargingData: data, fetchChargingRecord, refreshing, setRefreshing } = chargingRecordState;
  const [refreshButtonShow, setRefreshButtonShow] = useState(false);
  const [autoRefreshCount, setAutoRefreshCount] = useState(0);
  const [autoRefreshDelay, setAutoRefreshDelay] = useState(15000);
  const autoRefreshMaxCount = 12;

  useEffect(() => {
    amplitude.chargingPlugHintPageView();
  }, []);

  useInterval(() => {
    fetchChargingRecord();
    setAutoRefreshCount(count => count + 1);
  }, autoRefreshDelay);

  useEffect(() => {
    // 開始自動重新拉取 api 後才會顯示重整按鈕
    if (!refreshButtonShow && autoRefreshCount !== 0) {
      setRefreshButtonShow(true);
    }

    // 達到最大次數後就停止自動拉取 api
    if (autoRefreshCount === autoRefreshMaxCount) {
      setAutoRefreshDelay(null);
    }
  }, [refreshButtonShow, autoRefreshCount]);

  const RefreshButton = () => {
    const onRefresh = () => {
      amplitude.chargingPlugHintCheckOnClick({
        serialNumber,
        evseId: data.evseId,
        evseName: data.evse.uid,
        email: data.email,
      });
      setRefreshing(true);
      fetchChargingRecord();
    };

    return (
      <div className={cx({ refresh: true, on: refreshButtonShow })}>
        {refreshing ? (
          <span>檢查中...</span>
        ) : (
          <div onClick={onRefresh}>
            <img src={iconRefresh} alt="refresh" />
            重新檢查充電槍已插上
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles['in-waiting']}>
      <div className={styles.heading}>
        <div className={styles.title} data-testid="title">
          請將充電槍連結
        </div>
        <p data-testid="subtitle">預授權付款成功，請將充電槍連接上您的車輛，皮卡充即將可為您充電。</p>
      </div>
      <div className={styles.image}>
        <img src={imgConnect} alt="connect" />
        <div className={styles['dots-group']}>
          <div />
          <div />
          <div />
        </div>
      </div>
      {RefreshButton()}
    </div>
  );
};

const ChargingRecords = () => {
  const { serialNumber } = useParams();
  const chargingRecordState = useChargingRecordsProvider();
  const { loaded, chargingData } = chargingRecordState;

  if (!loaded) {
    return <Loading loadingText="請稍候..." />;
  }

  // 確認可顯示充電樁資訊的狀態
  const inWaitingState = [INIT, INIT_AUTH, START_SESSION, WAITING_CHARGING];
  const isWaiting = inWaitingState.indexOf(chargingData.state) !== -1;

  return (
    <Wrapper>
      <IconPnc className={styles.png} />
      <CSSTransition appear={true} in={isWaiting} timeout={300} classNames="waiting" mountOnEnter unmountOnExit>
        <InWaiting {...chargingRecordState} serialNumber={serialNumber} />
      </CSSTransition>
      <CSSTransition in={!isWaiting} timeout={300} classNames="charging" mountOnEnter>
        <InCharging {...chargingRecordState} serialNumber={serialNumber} />
      </CSSTransition>
    </Wrapper>
  );
};

const ChargingRecordsWrapper = ({ initial }) => {
  return (
    <ChargingRecordsProvider initial={initial}>
      <ChargingRecords />
    </ChargingRecordsProvider>
  );
};

export default ChargingRecordsWrapper;
